exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/resetPassword.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-erp-tsx": () => import("./../../../src/templates/erp.tsx" /* webpackChunkName: "component---src-templates-erp-tsx" */),
  "component---src-templates-logistics-tsx": () => import("./../../../src/templates/logistics.tsx" /* webpackChunkName: "component---src-templates-logistics-tsx" */),
  "component---src-templates-marketplace-tsx": () => import("./../../../src/templates/marketplace.tsx" /* webpackChunkName: "component---src-templates-marketplace-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-partner-tsx": () => import("./../../../src/templates/partner.tsx" /* webpackChunkName: "component---src-templates-partner-tsx" */),
  "component---src-templates-success-story-tsx": () => import("./../../../src/templates/successStory.tsx" /* webpackChunkName: "component---src-templates-success-story-tsx" */),
  "component---src-templates-webshop-tsx": () => import("./../../../src/templates/webshop.tsx" /* webpackChunkName: "component---src-templates-webshop-tsx" */)
}

